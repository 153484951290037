import { ref } from 'vue'

const isDialogOpen = ref(false)
const showContinueLater = ref(false)

export const useLawyerWizardDialogState = () => {
  const openLawyerWizardDialog = () => {
    isDialogOpen.value = true
    showContinueLater.value = false
  }

  const closeLawyerWizardDialog = () => {
    isDialogOpen.value = false
    showContinueLater.value = true
  }

  return {
    isDialogOpen: readonly(isDialogOpen),
    showContinueLater: readonly(showContinueLater),
    openLawyerWizardDialog,
    closeLawyerWizardDialog
  }
}
